import React from 'react';
import { Modal as BootstrapModal, Button } from 'react-bootstrap';
import './index.scss';

export interface ModalProps {
  title?: string;
  show: boolean;
  onClose: () => void;
  onSave?: () => void;
  saveButtonText?: string;
  children?: React.ReactNode; // Allows passing custom content
}

const Modal: React.FC<ModalProps> = ({ title, show, onClose, onSave, saveButtonText = 'Save', children }) => {
  return (
    <BootstrapModal show={show} onHide={onClose} centered>
      <BootstrapModal.Header closeButton>
        <BootstrapModal.Title>{title}</BootstrapModal.Title>
      </BootstrapModal.Header>
      <BootstrapModal.Body>{children}</BootstrapModal.Body>
      <BootstrapModal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        {onSave && (
          <Button variant="primary" onClick={onSave}>
            {saveButtonText}
          </Button>
        )}
      </BootstrapModal.Footer>
    </BootstrapModal>
  );
};

export default Modal;
