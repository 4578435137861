import DashboardComponent from '../screens/dashboard';
import TestingToolComponent from '../screens/testing-tool';
import SemanticCacheEditComponent from '../screens/semantic-cache-edit';
import AmwayProductsCatalogComponent from '../screens/amway-products-catalog';
import ResponsesReportComponent from '../screens/responses-report';
import { Features } from './features';

export interface RouteData {
  label: string;
  path: string;
  feature: Features;
  /** If this is undefined, we have an unrestricted route */
  element: JSX.Element;
}

export const authenticatedRoutes: RouteData[] = [
  {
    label: 'Dashboard',
    path: '/dashboard',
    feature: Features.DashboardScreen,
    element: <DashboardComponent />,
  },
  {
    label: 'Testing tool',
    path: '/testing-tool',
    feature: Features.TestingToolScreen,
    element: <TestingToolComponent />,
  },
  {
    label: 'Semantic Cache Edit',
    path: '/semantic-cache-edit',
    feature: Features.SemanticCacheEditScreen,
    element: <SemanticCacheEditComponent />,
  },
  {
    label: 'Amway Products Catalog',
    path: '/amway-products-catalog',
    feature: Features.AmwayProductsCatalogScreen,
    element: <AmwayProductsCatalogComponent />,
  },
  {
    label: 'Responses Report',
    path: '/responses-report',
    feature: Features.ResponsesReportScreen,
    element: <ResponsesReportComponent />,
  },
];
