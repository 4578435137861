import { Authorization, ClaimPermissions, FeaturesMap, Permission } from './features-types';

export const authorizationMapper = (apiData: any): Authorization => {
  return {
    // TODO: uncomment if permissions and features are needed.
    // permissions: permissionsMapper(apiData),
    // features: featuresMapper(apiData),
    permissions: [],
    features: ['DashboardScreen', 'SemanticCacheEditScreen', 'AmwayProductsCatalogScreen', 'ResponsesReportScreen'],
  };
};

const permissionsMapper = (apiData: any): Permission[] => {
  return apiData.permissions as Permission[];
};

const featuresMapper = (apiData: any): string[] => {
  return apiData.features.map((feat: any) => feat.name);
};

export const claimsPermissionsMapper = (apiData: any): ClaimPermissions[] => {
  return Object.entries(apiData.claims).flatMap(entry => {
    const [securityEnvironment, claims] = entry;

    return (claims as any[]).map((claim: any) => ({
      name: `${securityEnvironment}/${claim.claimName}`,
      permissions: claim.permissions as Permission[],
    }));
  });
};

export const featuresMapMapper = (apiData: any): FeaturesMap => {
  return apiData.features;
};
