import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import Icon from '@mdi/react';
import { Typography } from '@amway/react-components';
import { Card, Col, Container, Row } from 'react-bootstrap';
import MultiSelectDropdown, { Item } from '../../components/shared/multi-select-dropdown';
import Input from '../../components/shared/input';
import { mdiDelete, mdiFileEdit } from '@mdi/js';
import { MdOutlineSearch, MdAdd } from 'react-icons/md';
import LoadingComponent from '../../components/shared/loading-component';
import { SearchRequest, EditProductRequest } from '../../interface/products-catalog';
import PaginatedTableComponent, { Column } from '../../components/shared/paginated-table';
import Modal from '../../components/shared/modal';
import DropdownBtn, { Props as DropdownBtnProps } from '../../components/shared/dropdown-btn';
import productsCatalogService from '../../service/products-catalog.service';
import Button from '../../components/shared/button';
import { mdiPlusCircleOutline } from '@mdi/js';
import './index.scss';

export const columns: Column[] = [
  {
    id: 'brand_name',
    label: 'Brand Name',
    maxWidth: 15,
  },
  {
    id: 'item_name',
    label: 'Item Name',
    maxWidth: 20,
  },
  {
    id: 'upc',
    label: 'UPC',
    maxWidth: 10,
  },
  {
    id: 'serving_quantity',
    label: 'Serving Quantity',
    maxWidth: 10,
  },
  {
    id: 'serving_measure',
    label: 'Serving Measure',
    maxWidth: 10,
  },
  {
    id: 'serving_size',
    label: 'Serving Size',
    maxWidth: 10,
  },
  {
    id: 'unit',
    label: 'Unit',
    maxWidth: 10,
  },
  {
    id: 'servings_per_container',
    label: 'Servings per Container',
    maxWidth: 10,
  },
  {
    id: 'label',
    label: 'Label',
    maxWidth: 15,
  },
  {
    id: 'category',
    label: 'Category',
    maxWidth: 20,
  },
  {
    id: 'nutrition_values',
    label: 'Nutrition Values',
    maxWidth: 30,
  },
  {
    id: 'ingredient_statement',
    label: 'Ingredient Statement',
    maxWidth: 30,
  },
  {
    id: 'delete',
    label: 'Actions',
    align: 'right',
    sx: () => ({
      position: 'sticky',
      right: 0,
      backgroundColor: '#fff',
      zIndex: 999,
    }),
  },
];

const makeItems = (items: Item[]): any['items'] => {
  return items;
};

export default function AmwayProductsCatalogComponent() {
  const [brands, setBrands] = useState<Item[]>();
  const [selectedBrands, setSelectedBrands] = useState<string[]>();
  const [itemName, setItemName] = useState('');
  const [labels, setLabels] = useState<Item[]>();
  const [selectedLabels, setSelectedLabels] = useState<string[]>();
  const [categories, setCategories] = useState<Item[]>();
  const [selectedCategories, setSelectedCategories] = useState<string[]>();
  const [loading, setLoading] = useState(false);
  const [emptyResponse, setEmptyResponse] = useState<boolean>(false);
  const [productsContentItems, setProductsContentItems] = useState<any[]>([]);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [isModalVisible, setModalVisible] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState<string>();
  const [selectedModalLabel, setSelectedModalLabel] = useState<string>();
  const [selectedModalCategory, setSelectedModalCategory] = useState<string>();
  const [selectedProductId, setSelectedProductId] = useState<number>();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const brandItems = useMemo(() => makeItems(brands ?? []), [brands]);
  const labelItems = useMemo(() => makeItems(labels ?? []), [labels]);
  const categoryItems = useMemo(() => makeItems(categories ?? []), [categories]);

  useEffect(() => {
    async function fetchBrands() {
      const _brands = await productsCatalogService.getBrandNames();

      const updatedBrandNames = _brands.map(brand => ({
        ...brand,
        value: brand.name,
        immutable: false,
      }));

      setBrands(updatedBrandNames);
    }
    async function fetchCategories() {
      const _categories = await productsCatalogService.getCategories();

      const updatedCategories = _categories.map(category => ({
        ...category,
        value: category.name,
        immutable: false,
      }));
      setCategories(updatedCategories);
    }

    fetchBrands();
    fetchCategories();

    const labelNames = ['Green', 'Yellow', 'Red'];
    const _labels = labelNames.map((name, index) => ({
      name,
      label: name,
      value: name.toLowerCase(),
      immutable: false,
      id: index,
    }));
    setLabels(_labels);
  }, []);

  const handleBrandsChanges = (items?: string[]) => {
    setSelectedBrands(items!);
  };

  const handleLabelsChanges = (items?: string[]) => {
    setSelectedLabels(items!);
  };

  const handleCategoriesChanges = (items?: string[]) => {
    setSelectedCategories(items!);
  };

  const handleProductSearch = async () => {
    setProductsContentItems([]);

    // if (!itemName) {
    //   console.log('missing item name');
    //   // TODO: give feedback to the user.
    //   alert('missing item name');
    //   return;
    // }

    setLoading(true);

    const params: SearchRequest = {
      item_name: itemName!,
      ...(selectedBrands?.length !== brandItems.length && { brand_name: selectedBrands!.join(',') }),
      ...(selectedLabels?.length !== labelItems.length && { label: selectedLabels!.join(',') }),
      ...(selectedCategories?.length !== categoryItems.length && { category: selectedCategories!.join(',') }),
    };

    try {
      const _data = await productsCatalogService.search(params);

      if (!_data || _data.length === 0) {
        setEmptyResponse(true);
      } else {
        setEmptyResponse(false);

        // Parse response, add delete icon and actions
        _data.forEach((entry: any) => {
          entry.nutrition_values = JSON.stringify(entry.nutrition_values);
          entry.id = entry.product_id;
          entry.delete = (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span
                onClick={event => {
                  event.stopPropagation();
                  handleProductsCatalogEdit([entry]);
                }}
                style={{ cursor: 'pointer', textAlign: 'center', display: 'block', margin: 'auto' }}>
                <Icon path={mdiFileEdit} size={1} color="green" />
              </span>
              <span
                onClick={event => {
                  event.stopPropagation();
                  handleProductsCatalogDelete([entry.product_id]);
                }}
                style={{ cursor: 'pointer', textAlign: 'center', display: 'block', margin: 'auto' }}>
                <Icon path={mdiDelete} size={1} color="red" />
              </span>
            </div>
          );
        });

        setProductsContentItems(_data);
      }
    } catch (error) {
      console.error('Error during products catalog search:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleProductsCatalogDelete = async (ids: any[]) => {
    setLoading(true);
    const response = await productsCatalogService.deleteEntries(ids);
    setLoading(false);

    if (response.status_code === 200) {
      setProductsContentItems(prevItems => prevItems.filter(item => !ids.includes(item.product_id)));
    } else {
      console.error(`Failed to delete item with IDs ${ids}`);
    }
  };

  const handleProductsCatalogEdit = async (entry: any) => {
    if (entry[0]) {
      let foundLabel = labelItems.find((label: any) => label.name.toLowerCase() === entry[0].label.toLowerCase());
      if (foundLabel) setSelectedModalLabel(foundLabel.id);
      setSelectedProductId(entry[0].product_id);
      setSelectedModalCategory(entry[0].category);
      setModalVisible(true);
    } else {
      console.error('Not able to edit current product');
    }
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const handleModalLabelChange: DropdownBtnProps['onClick'] = useCallback(
    item => {
      if (!item || !labels) return;
      setSelectedModalLabel(item.label);
    },
    [labels],
  );

  const handleSave = async () => {
    if (!selectedProductId || !selectedModalLabel || !selectedModalCategory) {
      alert('Could not edit product');
      return;
    }

    const params: EditProductRequest = {
      product_id: selectedProductId!,
      label: selectedModalLabel!,
      category: selectedModalCategory!,
    };

    setLoading(true);
    const response = await productsCatalogService.editProduct(params);
    setLoading(false);

    if (response.status_code === 200) {
      setProductsContentItems(prevItems =>
        prevItems.map(item =>
          item.product_id === selectedProductId
            ? { ...item, label: selectedModalLabel, category: selectedModalCategory }
            : item,
        ),
      );
    } else {
      console.error(`Failed to delete product with ID ${selectedProductId}`);
    }
    setModalVisible(false);
  };

  // Open the file picker
  const handleImportButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  // Handle file selection and upload
  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const selectedFile = event.target.files[0];

      setIsUploading(true);

      const formData = new FormData();
      formData.append('file', selectedFile);

      try {
        const response = await productsCatalogService.import(formData);

        if (response.status_code === 201) {
          alert('File uploaded successfully!');
        } else {
          const errorData = await response.json();
          alert(`File upload failed: ${errorData.message}`);
        }
      } catch (error) {
        console.error('Error uploading file:', error);
        alert('An error occurred while uploading the file.');
      } finally {
        setIsUploading(false);
        event.target.value = '';
      }
    }
  };

  return (
    <Container className="amway-products-catalog">
      <Card>
        <div className="header">
          <Typography variant="heading">Amway Products Catalog</Typography>

          <div className="file-upload-container">
            <Button variant="primary" className="import-btn" onClick={handleImportButtonClick} disabled={isUploading}>
              <Icon path={mdiPlusCircleOutline} size={1} />
              {isUploading ? 'Importing...' : 'Import Products from CSV'}
            </Button>
            <input
              type="file"
              accept=".csv"
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />
          </div>
        </div>

        <section className="products-filter-container">
          <MultiSelectDropdown
            id="dropdown-brand"
            variant="tertiary"
            required={true}
            none
            disabled={!brands}
            all={true}
            allPreSelected={true}
            label="Brand Name"
            placeholder="Select Brand Name"
            items={brandItems}
            value={selectedBrands} // Map to string[]
            onChange={handleBrandsChanges}
          />

          <Input
            title="Item Name"
            required
            placeholder="Item name to be searched"
            className="item-name-input"
            value={itemName}
            onChange={e => setItemName(e.target.value)}
          />

          <MultiSelectDropdown
            id="dropdown-label"
            variant="tertiary"
            required={true}
            none
            disabled={!labels}
            all={true}
            allPreSelected={true}
            label="Label"
            placeholder="Select Label"
            items={labelItems}
            value={selectedLabels} // Map to string[]
            onChange={handleLabelsChanges}
          />

          <MultiSelectDropdown
            id="dropdown-category"
            variant="tertiary"
            required={true}
            none
            disabled={!categories}
            all={true}
            allPreSelected={true}
            label="Category"
            placeholder="Select Category"
            items={categoryItems}
            value={selectedCategories} // Map to string[]
            onChange={handleCategoriesChanges}
          />

          <Button variant="secondary" className="search-btn" onClick={handleProductSearch}>
            Search
            <MdOutlineSearch />
          </Button>
        </section>
      </Card>
      {(productsContentItems.length > 0 || emptyResponse || loading) && (
        <Card>
          <Card.Body>
            {loading ? (
              <div className="loading-div">
                <LoadingComponent className="ms-2 spinner" />
              </div>
            ) : (
              <>
                <Row>
                  <Col>
                    <Card.Title>{emptyResponse ? 'No products catalog entries found' : 'Preview'}</Card.Title>
                  </Col>
                </Row>
                {!emptyResponse && (
                  <Row>
                    <Col className="justify-content-center">
                      <PaginatedTableComponent
                        columns={columns}
                        rows={productsContentItems}
                        rowIdPropName="product_id"
                        selectableRows={true}
                        setSelectedRows={setSelectedRows}
                        handleDeleteSelected={handleProductsCatalogDelete}
                        // sx={{ maxWidth: 'calc(100vw - 307px - 2*24px - 32px - 38px - 19px)' }}
                      />
                    </Col>
                  </Row>
                )}
              </>
            )}
          </Card.Body>
        </Card>
      )}

      <Modal
        title="Edit Label and Category"
        show={isModalVisible}
        onClose={handleCloseModal}
        onSave={handleSave}
        saveButtonText="Save">
        <DropdownBtn
          id="label-selector"
          className="label-selector"
          variant="secondary"
          value={selectedModalLabel}
          items={labelItems}
          label="Label"
          placeholder="Select a label"
          onClick={handleModalLabelChange}
        />
        <Input
          title="Category"
          required
          placeholder="Categories"
          className="category-input"
          value={selectedModalCategory}
          onChange={e => setSelectedModalCategory(e.target.value)}
        />
      </Modal>
    </Container>
  );
}
