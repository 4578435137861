import env from '../config/env';
import { SearchRequest, EditProductRequest } from '../interface/products-catalog';
import { AuthorizedService } from './authorized.service';

class ProductsCatalogService extends AuthorizedService {
  public search(params: SearchRequest): Promise<any[]> {
    return super.get<any[]>('products_catalog/search', {
      params: params,
    });
  }

  public async getBrandNames(): Promise<any[]> {
    const response = await super.get<{ brands: string[] }>('products_catalog/brands');
    return response.brands.map((brand, index) => ({
      id: index,
      name: brand,
    }));
  }

  public async getCategories(): Promise<any[]> {
    const response = await super.get<{ categories: string[] }>('products_catalog/categories');
    return response.categories.map((category, index) => ({
      id: index,
      name: category,
    }));
  }

  public deleteEntries(id: number[]): Promise<any> {
    return super.delete<any>('products_catalog/delete', {
      data: { id_list: id },
    });
  }

  public editProduct(params: EditProductRequest): Promise<any> {
    return super.post<any, any>('products_catalog/update', {
      product_id: params.product_id,
      label: params.label,
      category: params.category,
    });
  }

  public import(formData: FormData): Promise<any> {
    return super.post<any, any>('products_catalog/import', formData);
  }
}

const productsCatalogService = new ProductsCatalogService(env.API_URL);
export default productsCatalogService;
