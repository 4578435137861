import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Card, Container } from 'react-bootstrap';
import ComingSoonComponent from '../coming-soon';
import './index.scss';

export default function ResponsesReportComponent() {
  return (
    <Container className="responses-report">
      <Card>
        <Card.Body>
          <ComingSoonComponent />
        </Card.Body>
      </Card>
    </Container>
  );
}
